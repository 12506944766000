import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import React, { useState } from 'react'
import { AxiosInstance } from 'axios'

import { setupApi } from './setupApi'
import { ApiError } from '../types'

type MainContextType = {
  state: { error?: ApiError; isLoading: boolean }
  actions: {
    setIsLoading(newValue: boolean): void
    setError(newValue: ApiError | undefined): void
  }
  contractsApi: AxiosInstance
  talentApi: AxiosInstance
  authApi: AxiosInstance
}

const MainContext = React.createContext<MainContextType>({} as MainContextType)

const MainContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = useState<ApiError>()
  const [isLoading, setIsLoading] = useState(false)

  const state = { error, isLoading }
  const actions = { setError, setIsLoading }

  const contractsApi = setupApi('/v1/contracts', actions)
  const talentApi = setupApi('/v1/talent', actions)
  const authApi = setupApi('/v2/auth', actions)

  return (
    <MainContext.Provider
      value={{ state, actions, talentApi, authApi, contractsApi }}
    >
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: `center`,
            alignItems: `center`,
            height: '100vh',
          }}
        >
          <img src="/img/loader1.gif" alt="loading..."></img>
        </div>
      ) : (
        children
      )}

      {!isLoading && error && (
        <Modal
          basic
          onClose={() => setError(undefined)}
          open={true}
          size="small"
          closeOnDimmerClick={false}
          dimmer="blurring"
        >
          <Header icon>
            <Icon name="bolt" />
            Opps...
          </Header>
          <Modal.Content>
            <p style={{ fontSize: 20 }}>{error.message}</p>
            <p></p>
            <p>
              <b>RequestId:</b> {error.requestId} (Send this code to Lean
              please)
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              inverted
              color="blue"
              onClick={() => (document.location = '/')}
            >
              <Icon name="checkmark" /> Ok!
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </MainContext.Provider>
  )
}

const useContractsApi = () => React.useContext(MainContext).contractsApi
const useMainActions = () => React.useContext(MainContext).actions
const useMainState = () => React.useContext(MainContext).state
const useTalentApi = () => React.useContext(MainContext).talentApi
const useAuthApi = () => React.useContext(MainContext).authApi

export {
  MainContextProvider as Provider,
  MainContext as default,
  useMainActions,
  useMainState,
  useContractsApi,
  useTalentApi,
  useAuthApi,
}
