import React from 'react'
import { Button } from 'semantic-ui-react'

const TrEditButton = ({ onClick, visible }) => (
  <Button
    icon="pencil"
    size="tiny"
    onClick={onClick}
    style={{
      position: 'absolute',
      right: 16,
      top: 8,
      // float: 'right',
      padding: 7,
      margin: 0,
      marginTop: -2,
      backgroundColor: '#fff',
      border: 'solid 1px var(--border-grey)',
      display: visible ? 'block' : 'none',
      zIndex: 2,
    }}
  />
)

export default TrEditButton
