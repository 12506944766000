import React, { useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { useHistory } from 'react-router'

import { useTalentApi } from '../../store/mainContext'
import SearchContacts from '../../components/search/searchContacts'
import FunnelPage from './funnelPage'
import AddContactModal from '../../components/modals/addContactModal'
import styled from 'styled-components'

const Sub = styled.div`
  margin-top: 7px;
  font-size: 14px;
  color: #747474;
  line-height: 20px;
`

const ContactsFunnel = (props) => {
  const history = useHistory()
  const api = useTalentApi()

  const [openModal, setOpenModal] = useState(false)

  if (!props) return null

  const getData = async ({ statuses }) => {
    // 1. Get candidates
    const { data: contacts } = await api.get(`contacts/in`, {
      params: { status: statuses },
    })

    const [{ data: tasks }, { data: comp }] = await Promise.all([
      // 2. Get Tasks for those candidates
      api.get(`tasks/in`, {
        params: { relatedId: contacts.map((x) => x._id) },
      }),

      // 3. Get Positions for those candidates
      api.get(`companies/in`, {
        params: { _id: contacts.map((x) => x.companyId) },
      }),
    ])

    // Put everything together
    return contacts.map((c) => {
      c.tasks = tasks.filter((x) => x.relatedId === c._id && !x.isDone)
      c.company = comp.find((company) => company._id === c.companyId)

      return c
    })
  }

  return (
    <React.Fragment>
      <FunnelPage
        collectionName="contacts"
        toolBar={
          <React.Fragment>
            <SearchContacts />
            <Button
              basic
              onClick={() => setOpenModal(true)}
              style={{ marginLeft: 10 }}
            >
              <Icon name="add" /> Add Contact
            </Button>
          </React.Fragment>
        }
        CardContent={ContactCard}
        getData={getData}
        {...props}
      />

      <AddContactModal
        onCancel={() => setOpenModal(false)}
        open={openModal}
        onCreated={(data) => {
          history.push(`/contacts/${data._id}`)
        }}
      />
    </React.Fragment>
  )
}

const ContactCard = ({ card }) => (
  <React.Fragment>
    {card.position && <Sub>{card.position}</Sub>}

    {card.company && (
      <Sub>
        <b>{card.company.name}</b>
      </Sub>
    )}
  </React.Fragment>
)

export default ContactsFunnel
