import React from 'react'
import styled from 'styled-components'
import RemoteValue from './remoteValue'

// const Container = styled.div``;

const Label = styled.div`
  border: solid 1px var(--border-grey);
  background-color: var(--bg-grey);

  padding: 3px 7px;
  margin: 3px;
  float: left;
  border-radius: 4px;
  font-size: 12px;
`

const RemoteLabels = ({ ids = [], collection }) =>
  ids.map((p) => (
    <Label key={p}>
      <RemoteValue collection={collection} id={p} />
    </Label>
  ))

export default RemoteLabels
