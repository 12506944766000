import React, { useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import PageHeader from '../../components/pageHeader/pageHeader'

import PagedTable from '../../components/dataTable/pagedTable'
import RemoteValue from '../../components/remoteValues/remoteValue'
import AddContactModal from '../../components/modals/addContactModal'
import Period from '../../components/period'
import SearchContacts from '../../components/search/searchContacts'

const PageContent = styled.div`
  margin: 0px 50px 50px 50px;
`

const Contacts = () => {
  document.title = 'Contacts'

  const history = useHistory()

  const [showAddModal, setShowAddModal] = useState(false)

  const handleCreated = (c) => {
    history.push(`/contacts/${c._id}`)
  }

  return (
    <React.Fragment>
      <PageHeader
        breadcrumb={[
          { text: 'Dashboard', link: '/' },
          { text: 'Contacts', link: '/contacts' },
        ]}
        title="Contacts"
        sub="One screen to contact them all."
        search={<SearchContacts />}
        actions={
          <React.Fragment>
            <Button basic onClick={() => setShowAddModal(true)}>
              <Icon name="add" /> Add Contact
            </Button>
          </React.Fragment>
        }
      />

      <PageContent>
        <PagedTable
          onRowClick={(item) => window.open(`/contacts/${item._id}`, '_blank')}
          defaultSort="lastUpdateOn"
          collection="contacts"
          columns={[
            {
              title: 'Name',
              field: 'name',
              width: 200,
            },
            {
              title: 'Position',
              field: 'position',
              width: 200,
            },
            {
              title: 'Company',
              field: 'companyId',
              width: 200,
              align: 'center',

              render: (companyId) => (
                <RemoteValue
                  collection="companies"
                  id={companyId}
                  key={companyId}
                  predicate={(x) => x.name}
                  showLoading={true}
                />
              ),
            },
            {
              title: 'Email',
              field: 'email',
            },
            {
              title: 'Timezone',
              field: 'timezoneId',
              render: (timezoneId) => (
                <RemoteValue
                  collection="timezones"
                  id={timezoneId}
                  key={timezoneId}
                  predicate={(x) => x.text}
                />
              ),
            },
            {
              title: 'Last Update',
              field: 'lastUpdateOn',
              render: (lastUpdateOn) => <Period date={lastUpdateOn} />,
            },
          ]}
        />
      </PageContent>

      <AddContactModal
        open={showAddModal}
        onCancel={() => setShowAddModal(false)}
        onCreated={handleCreated}
      />
    </React.Fragment>
  )
}

export default Contacts
