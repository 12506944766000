import React from 'react'

import SessionContext from './sessionContext'

export function useUser() {
  const {
    state: { user },
  } = React.useContext(SessionContext)

  return user
}

export function useSessionActions() {
  return React.useContext(SessionContext).actions
}

export function useSessionState() {
  return React.useContext(SessionContext).state
}
