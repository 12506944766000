import React, { useState } from 'react'
import styled from 'styled-components'
import SimpleFilter from './simpleFilter'
import DataFilter from './dataFilter'

const FilterBar = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 10px 0px;
  /* position: -webkit-sticky; */
  /* position: sticky; */
  top: 0;
  background-color: #fff;
  /* border-bottom: solid 1px var(--border-grey);
  margin-bottom: -1px; */
  z-index: 1;
  width: 800px;
`

const FilterLabel = styled.div`
  margin: 5px;
  text-align: center;
`

const PositionsFilter = ({ onFilterChange }) => {
  const [filter, setFilter] = useState({})

  const [view] = useState([
    { value: 'open', key: 0, text: 'Open Positions' },
    { value: 'closed', key: 1, text: 'Closed Positions' },
  ])

  const [priority] = useState([
    { value: null, key: 0, text: 'All Priorities' },
    { value: 'true', key: 1, text: 'High Priority' },
    { value: 'false', key: 1, text: 'Normal Priority' },
  ])

  const [order] = useState([
    { value: '0', key: 0, text: 'Oldest Opening' },
    { value: '1', key: 1, text: 'Newest Opening' },
    { value: '2', key: 1, text: 'Last Changed' },
    // { value: '3', key: 1, text: "Last Changed" },
  ])

  const handleChange = (additionalFilter) => {
    const newFilter = {
      ...filter,
      ...additionalFilter,
    }

    if (!newFilter.status) {
      newFilter.status = 'open'
    }

    setFilter(newFilter)
    onFilterChange && onFilterChange(newFilter)
  }

  return (
    <FilterBar>
      <FilterLabel>Showing</FilterLabel>
      <SimpleFilter
        options={view}
        name="status"
        onFilterChange={handleChange}
      />

      <FilterLabel>with</FilterLabel>
      <SimpleFilter
        options={priority}
        name="isHighPriority"
        onFilterChange={handleChange}
        disabled={filter.status === 'closed'}
      />

      <FilterLabel>for</FilterLabel>
      <DataFilter
        onFilterChange={handleChange}
        name="companyId"
        label="All Companies"
        collection="companies"
        optionRender={(x) => x.name}
      />

      <FilterLabel>ordered by</FilterLabel>
      <SimpleFilter
        options={order}
        name="order"
        onFilterChange={handleChange}
        disabled={true}
      />
    </FilterBar>
  )
}

export default PositionsFilter
