import * as React from 'react'
import { Button, Confirm, Modal } from 'semantic-ui-react'

const ConfirmClosePositionModal = ({
  show,
  positionTitle,
  onConfirm,
  onCancel,
}) => (
  <Confirm
    open={show}
    header={'Hey...Closing this position?'}
    content={
      <Modal.Content>
        <p>Are you sure you close this position:</p>

        <p className="highlight" style={{ fontSize: 17 }}>
          <b>{positionTitle}</b>
        </p>

        <p>Are you sure?</p>
      </Modal.Content>
    }
    onCancel={onCancel}
    closeOnDimmerClick={true}
    size="tiny"
    onConfirm={onConfirm}
    confirmButton={<Button negative>Yeap...close.</Button>}
    cancelButton={<Button basic>Ugh... no, no yet.</Button>}
  />
)

export default ConfirmClosePositionModal
