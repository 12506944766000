import React, { useState } from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'
import ConfirmModal from '../modals/confirmModal'

const SubmissionsMenu = ({ onShowHistory, onRemove, onAdd }) => {
  const [showConfirm, setShowConfirm] = useState(false)

  return (
    <React.Fragment>
      <Dropdown
        icon={null}
        trigger={<Icon name="ellipsis horizontal" />}
        style={{ marginRight: 10 }}
        basic
        pointing="top right"
      >
        <Dropdown.Menu>
          <Dropdown.Item text="Add New Submission" icon="add" onClick={onAdd} />

          <Dropdown.Item
            text="View History"
            icon="history"
            onClick={onShowHistory}
          />

          <Dropdown.Divider />

          <Dropdown.Item
            onClick={() => setShowConfirm(true)}
            content={
              <div style={{ color: 'var(--red)' }}>
                <b>
                  <Icon name="trash alternate outline" />
                  Remove
                </b>
              </div>
            }
          />
        </Dropdown.Menu>
      </Dropdown>

      <ConfirmModal
        title="Removing Submission"
        show={showConfirm}
        question={
          <React.Fragment>
            You are about to remove this submittion.{' '}
            <b>This action can not be undone.</b>
          </React.Fragment>
        }
        onCancel={() => setShowConfirm(false)}
        onConfirm={() => {
          setShowConfirm(false)
          onRemove()
        }}
      />
    </React.Fragment>
  )
}

export default SubmissionsMenu
