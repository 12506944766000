import React from 'react'

import { DragDropContext } from 'react-beautiful-dnd'
import { useTalentApi } from '../../store/mainContext'

const Board = ({ funnel, onDragSuccess, children }) => {
  const api = useTalentApi()

  const updateCard = async (collection, id, status) => {
    await api.patch(`${collection}/${id}`, { status })
  }

  const handleDragEnd = async (result) => {
    const {
      destination,
      source,
      // , draggableId
    } = result
    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const start = funnel.columns.find((x) => x.id === source.droppableId)
    const finish = funnel.columns.find((x) => x.id === destination.droppableId)

    const card = start.cards.find((x) => x._id === result.draggableId)

    const newCards = Array.from(start.cards)

    // reordering column
    if (start === finish) {
      newCards.splice(source.index, 1)
      newCards.splice(destination.index, 0, card)

      const newColumn = {
        ...start,
        cards: newCards,
      }

      const newFunnel = {
        ...funnel,
      }

      newFunnel.columns[funnel.columns.indexOf(start)] = newColumn

      onDragSuccess(newFunnel)
      return
    }

    updateCard(funnel.collection, card._id, finish.id)
    card.lastStatusUpdate = Date.now() // TODO Revisar esto.

    newCards.splice(source.index, 1)

    const newStart = {
      ...start,
      cards: newCards,
    }

    const finishCards = Array.from(finish.cards)

    finishCards.splice(destination.index, 0, card)

    const newFinish = {
      ...finish,
      cards: finishCards,
    }

    const newFunnel = {
      ...funnel,
    }

    newFunnel.columns[funnel.columns.indexOf(start)] = newStart
    newFunnel.columns[funnel.columns.indexOf(finish)] = newFinish

    onDragSuccess(newFunnel)
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="fx fn-board">{children}</div>
    </DragDropContext>
  )
}

export default Board
