import React from 'react'
import * as semantics from 'semantic-ui-react'
import styled from 'styled-components'

const Txt = styled(semantics.TextArea)`
  width: 100%;
  resize: none;
  height: 100px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  margin: 0px;
  background: #fff;
  border-radius: 0.28571429rem;
  padding: 0.67857143em 1em;
  outline: none;
`

const TextArea = (props) => {
  const className = props.error ? 'error' : ''

  return <Txt className={className} {...props} />
}

export default TextArea
