import React, { useState } from 'react'
import { Button, Icon, Input } from 'semantic-ui-react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
`

const FilterPopup = styled.div`
  background-color: #fff;
  padding: 15px;
  border: solid 1px var(--border-grey);
  position: absolute;
  top: 35px;
  right: 0px;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgb(34 36 38 / 15%);
  width: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 1;
  z-index: 1;
`

const SearchFilter = ({ name, label, onFilterChange }) => {
  const [value, setValue] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [filter, setFilter] = useState()

  const [disableOnBlur, setDisableOnBlur] = useState(true)

  const handleApply = () => {
    setValue(filter)
    handleClose()

    const filterValue = {}
    filterValue[name] = filter

    // alert(JSON.stringify(filterValue));

    onFilterChange && onFilterChange(filterValue)
  }

  const handleClose = () => {
    setDisableOnBlur(true)
    setIsOpen(false)
    setFilter()
  }

  const handleOpen = () => {
    setDisableOnBlur(true)
    setIsOpen(true)
    setFilter(null)
  }

  const handleRemoveFilter = () => {
    setFilter(null)
    setValue(null)

    const filterValue = {}
    filterValue[name] = null

    onFilterChange && onFilterChange(filterValue)
  }

  const handleOnBlur = () => {
    if (disableOnBlur) return
    handleClose()
  }

  if (value) {
    return (
      <Button
        size="tiny"
        color="black"
        icon
        labelPosition="right"
        onClick={handleRemoveFilter}
      >
        <Icon name="remove" />
        <b>{value}</b>
      </Button>
    )
  }

  return (
    <Container
      onMouseEnter={() => setDisableOnBlur(true)}
      onMouseLeave={() => setDisableOnBlur(false)}
    >
      <Button
        size="tiny"
        active={isOpen}
        basic
        onClick={handleOpen}
        disabled={isOpen}
      >
        <b>{label}</b>
      </Button>

      {isOpen && (
        <FilterPopup
          onBlur={handleOnBlur}
          onMouseEnter={() => setDisableOnBlur(true)}
          onMouseLeave={() => setDisableOnBlur(false)}
        >
          <Input
            autoFocus
            placeholder="Location..."
            onChange={(e) => setFilter(e.target.value)}
            style={{ width: `100%`, marginRight: 10 }}
          />

          <Button size="tiny" basic onClick={handleClose} icon="cancel" />
          <Button
            size="tiny"
            color="black"
            onClick={handleApply}
            icon="check"
          />
        </FilterPopup>
      )}
    </Container>
  )
}

export default SearchFilter
