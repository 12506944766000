import * as React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  flex-direction: column;
  /* border: dotted 1px var(--border-grey); */
  /* background-color: var(--bg-grey); */

  height: 100px;
  border-radius: 4px;
`

const Sub = styled.span`
  color: #666;
`

const Logo = styled.img`
  height: 100px;
`

const NotFound = ({
  style,
  message = 'No candidates here yet.',
  sub = 'Good luck next time.',
}) => (
  <Container style={style}>
    <Logo src="/img/notfound.png" alt="not found" />

    <h2>{message}</h2>
    <Sub>{sub}</Sub>
  </Container>
)

export default NotFound
