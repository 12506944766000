import * as React from 'react'
import { useState } from 'react'
import {
  Button,
  Checkbox,
  // , FormCheckbox
  Modal,
} from 'semantic-ui-react'
import styled from 'styled-components'
// import DataSelect from '../form/dataSelect'
import PositionSelect from '../form/positionSelect'

const Title = styled.h2`
  font-weight: bold !important;
`

const OptContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`

const Toogle = styled(Checkbox)`
  margin-right: 10px;
`

const ChoosePositionModal = ({ show, onPositionSelected, onCancel }) => {
  const [value, setValue] = useState()

  const [filter, setFilter] = useState({ status: 'open' })

  if (!show) {
    return null
  }

  return (
    <Modal onClose={onCancel} open={true} size="tiny" closeOnDimmerClick={true}>
      <Modal.Content>
        <Title>Choose Position</Title>

        <p>
          You can search for the position using{' '}
          <span className="highlight">it&apos;s title</span>,{' '}
          <span className="highlight">company name</span> or{' '}
          <span className="highlight">hiring manager</span>.
        </p>

        <PositionSelect
          fluid
          search
          onChange={(e, option) => setValue(option.value)}
          filter={filter}
        ></PositionSelect>

        <OptContainer>
          <Toogle
            toggle
            onChange={(e, t) =>
              setFilter({ status: t.checked ? null : 'open' })
            }
          />{' '}
          Show closed positions too.
        </OptContainer>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={onCancel}>
          Nevermind...
        </Button>
        <Button
          disabled={!value}
          color="black"
          onClick={() => onPositionSelected(value)}
        >
          Yeap, that one.
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ChoosePositionModal
