import React from 'react'
import styled from 'styled-components'
import Card from '../../components/funnel/card'

import Period from '../../components/period'
import TasksMenu from './tasksMenu'

const Container = styled.div`
  padding: 10px;
  position: relative;
  min-height: 80px;
`

const Title = styled.h1`
  font-size: 16px;
  font-weight: bold !important;
  margin: 0px;
`

const RottenTitle = styled.h1`
  font-size: 16px;
  font-weight: bold !important;
  margin: 0px;
  color: var(--red) !important;
`

const RottenInfo = styled.div`
  margin-top: 10px;
  color: var(--red);
`

const FunnelCard = ({
  collectionName,
  index,
  card,
  onScheduleTask,
  onTaskEdit,
  isLastColumn,
  isRotten,
  CardContent,
}) => (
  <Card id={card._id} index={index}>
    <Container>
      <div onClick={() => window.open(`/${collectionName}/${card._id}`)}>
        {isRotten ? (
          <RottenTitle>{card.name}</RottenTitle>
        ) : (
          <Title>{card.name}</Title>
        )}

        <CardContent card={card} />
      </div>

      <TasksMenu
        tasks={card.tasks}
        style={{ position: 'absolute', bottom: 3, right: 0 }}
        onScheduleTask={onScheduleTask}
        onTaskEdit={onTaskEdit}
        isLastColumn={isLastColumn}
      />

      {isRotten && (
        <RottenInfo>
          Moved <Period date={card.lastStatusUpdate} />!
        </RottenInfo>
      )}
    </Container>
  </Card>
)

export default FunnelCard
