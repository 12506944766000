import React, { useState } from 'react'
import styled from 'styled-components'
import PageHeader from '../../components/pageHeader/pageHeader'

const PageContent = styled.div`
  margin: 20px 40px;
`

const Frame = styled.iframe`
  width: 100%;
  border: dotted 1px var(--border-grey);
  height: 75vh;
  padding-top: -25px;
  border-radius: 4px;
`

const Recruiters = () => {
  const [, setIsLoading] = useState(true)

  document.title = 'Recruiters'

  return (
    <React.Fragment>
      <PageHeader
        breadcrumb={[
          { text: 'Dashboard', link: '/' },
          { text: 'Freelance Recruiters', link: '/recruiters' },
        ]}
        title="Freelance Recruiters"
        sub="List of freelance recruiters & agencies who will receive the positions email."
      />

      <PageContent>
        <Frame
          src="https://docs.google.com/spreadsheets/d/1YjO9oSu00ajRkhrIz8jotmUZbqARFJk7rwgEOIzYTBs/edit#gid=0"
          onLoad={() => setIsLoading(false)}
        />
      </PageContent>
    </React.Fragment>
  )
}

export default Recruiters
