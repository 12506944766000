import React, { useEffect, useState } from 'react'
import { useTalentApi } from '../../store/mainContext'

const FunnelName = ({ status, showLoading }) => {
  const api = useTalentApi()
  const [loading, setLoading] = useState(showLoading)
  const [value, setValue] = useState()

  useEffect(() => {
    if (!status) {
      setLoading(false)
      return
    }
    api
      .get(`funnels`, { params: { name: status.split('.')[0] } })
      .then(({ data: funnels }) => setValue((funnels[0] || {}).title))
      .finally(() => setLoading(false))
  }, [api, status])

  if (loading) {
    return <React.Fragment>Trying to remember...</React.Fragment>
  }

  if (!value) return <React.Fragment></React.Fragment>

  return <React.Fragment>{value}</React.Fragment>
}

export default FunnelName
