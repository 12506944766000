import * as React from 'react'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'

const Button = styled.div`
  cursor: pointer;
  margin: 10px 30px;
  padding: 10px;
  /* border-radius: 4px; */
  border-top: dotted 1px var(--border-grey);
  border-bottom: dotted 1px var(--border-grey);
  text-align: center;
  color: #858585;

  &:hover {
    border-top: solid 1px var(--border-grey);
    border-bottom: solid 1px var(--border-grey);
  }
`

const SideAddButton = ({ onClick, children, icon = 'plus' }) => (
  <Button onClick={onClick}>
    <Icon name={icon} />
    {children}
  </Button>
)

export default SideAddButton
