import React from 'react' // useState, // useEffect,
import styled from 'styled-components'
import RemoteValue from '../../components/remoteValues/remoteValue'
// import { useTalentApi } from '../../store/mainContext'
// import ProfilesLabels from '../../components/remoteValues/remoteLabels'
import RemoteLabels from '../../components/remoteValues/remoteLabels'
// import PageLoader from '../../components/pageLoader'
// import DateFormat from '../../components/dateFormat'
import Period from '../../components/period'
// import DataTable from '../../components/dataTable/dataTable'
import PagedTable from '../../components/dataTable/pagedTable'
// import RemoteStars from '../../components/remoteValues/remoteStars'
// import Badges from '../../components/remoteValues/badges'

const Container = styled.div`
  /* box-shadow: 0px 0px 5px 0 rgb(34 36 38 / 15%); */

  /* background-color: var(--bg-grey); */
  margin-top: 40px;
`

// const Card = styled.div`
//   background-color: #fff;
//   border: dotted 1px var(--border-grey);
//   border-radius: 4px;
//   margin-bottom: 20px;
//   padding: 20px;

//   &:hover {
//     background-color: var(--bg-grey);
//     cursor: pointer !important;
//   }
// `

// const Title = styled.h3`
//   color: var(--primary) !important;
//   font-weight: bold !important;
//   cursor: pointer;
//   margin: 0px;
// `

// const Name = styled.h2`
//   font-size: 17px;
//   font-weight: bolder !important;
// `

// const Line = styled.div`
//   border-top: dotted 1px var(--border-grey);
//   padding-top: 7px;
//   padding-bottom: 7px;
// `

// const Sub = styled.sub``

const RecommendedCandidates = ({
  profilesRequired = [],
  techsRequired = [],
  minimumYearsOfExperience = 0,
}) => {
  // const api = useTalentApi()

  // const [isLoading, setIsLoading] = useState(true)
  const filter = {
    yearsInTheIndustry: { $gt: Number(minimumYearsOfExperience) },
  }

  if (profilesRequired.length > 0) {
    filter.profiles = { $in: profilesRequired }
  }

  if (techsRequired.length > 0) {
    filter.mainTechs = { $all: techsRequired }
  }

  return (
    <Container>
      <h2>Recommended Candidates</h2>

      <p>Hi... here some candidates may be a good fit.</p>

      <PagedTable
        collection="candidates"
        filter={filter}
        onRowClick={(item) => window.open(`/candidates/${item._id}`, '_blank')}
        defaultSort="lastEvent"
        columns={[
          {
            title: 'Name',
            width: 150,
            field: 'name',
          },
          {
            title: 'Level',
            width: 120,
            align: 'center',
            field: 'seniority',
            render: (seniority) => (
              <RemoteValue
                collection="seniority"
                id={seniority}
                key={seniority}
              />
            ),
          },
          {
            title: 'Profiles',
            width: 120,
            align: 'center',
            field: 'profiles',
            render: (profiles) => (
              <RemoteLabels ids={profiles} collection="profiles" />
            ),
          },
          {
            title: 'Main Techs',
            width: 120,
            align: 'center',
            field: 'mainTechs',
            render: (mainTechs) => (
              <RemoteLabels ids={mainTechs} collection="techs" />
            ),
          },
          {
            title: 'Intended Fee',
            width: 90,
            align: 'center',
            field: 'salary',
            render: (salary) => salary && `$ ${salary}`,
          },
          {
            title: 'Location',
            width: 130,
            align: 'center',
            field: 'location',
          },
          {
            title: 'Y of E',
            width: 90,
            align: 'center',
            field: 'yearsInTheIndustry',
          },
          {
            title: 'Last Touch',
            width: 120,
            align: 'center',
            field: 'lastEvent',
            render: (lastEvent) => <Period date={lastEvent} />,
          },
        ]}
      />
    </Container>
  )
}

export default RecommendedCandidates
