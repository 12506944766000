import React from 'react' // , { useEffect, useState }
// import { Icon, Label } from 'semantic-ui-react'
import styled from 'styled-components'
// import DataTable from './dataTable/dataTable'
// import DateFormat from './dateFormat'
// import PageLoader from './pageLoader'
import RemoteValue from './remoteValues/remoteValue'
// import UserName from './remoteValues/userName'
// import RequirePowers from './requirePowers'
// import POWERS from '../powers'
import PagedTable from './dataTable/pagedTable'
import Period from './period'

const Container = styled.div`
  margin-right: 40px;
`

// const Title = styled.h2`
//   color: var(--grey) !important;
// `;

// const TechLabel = styled.div`
//   border: solid 1px var(--border-grey);
//   background-color: var(--bg-grey);

//   padding: 3px 7px;
//   margin: 3px;
//   float: left;
//   border-radius: 4px;
//   font-size: 12px;
// `

const SectionTitle = styled.h2`
  margin-top: 0 !important;
  margin-bottom: 5px !important;
`

const Sub = styled.p`
  color: #666;
`

const Section = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: 40px;
`

const ContactsDetail = ({ companyId }) => {
  return (
    <Container>
      <Section>
        <SectionTitle>Contacts</SectionTitle>

        <Sub>Here you will find contacts related to this company.</Sub>

        <PagedTable
          onRowClick={(item) => window.open(`/contacts/${item._id}`, '_blank')}
          defaultSort="lastUpdateOn"
          collection="contacts"
          filter={{ companyId }}
          columns={[
            {
              title: 'Name',
              field: 'name',
              width: 200,
            },
            {
              title: 'Position',
              field: 'position',
              width: 200,
            },
            {
              title: 'Company',
              field: 'companyId',
              width: 200,
              align: 'center',

              render: (cid) => (
                <RemoteValue
                  collection="companies"
                  id={cid}
                  key={cid}
                  predicate={(x) => x.name}
                  showLoading={true}
                />
              ),
            },
            {
              title: 'Email',
              field: 'email',
            },
            {
              title: 'Timezone',
              field: 'timezoneId',
              render: (timezoneId) => (
                <RemoteValue
                  collection="timezones"
                  id={timezoneId}
                  key={timezoneId}
                  predicate={(x) => x.text}
                />
              ),
            },
            {
              title: 'Last Update',
              field: 'lastUpdateOn',
              render: (lastUpdateOn) => <Period date={lastUpdateOn} />,
            },
          ]}
        />
      </Section>
    </Container>
  )
}

export default ContactsDetail
