import React, { useState, useEffect } from 'react'

import './funnel.css'
import styled from 'styled-components'

import { useTalentApi } from '../../store/mainContext'

import Board from '../../components/funnel/board'
import Column from '../../components/funnel/column'

import FunnelCard from './funnelCard'
import PageLoader from '../../components/pageLoader'

// import Filter from "../../components/filters/filter";
import {
  Button,
  // , Icon
} from 'semantic-ui-react'
import { useHistory, useParams } from 'react-router'
import DataSelect from '../../components/form/dataSelect'
import RequirePowers from '../../components/requirePowers'
import AddTaskModal from '../../components/modals/addTaskModal'
// import ShowIf from '../../components/showIf'

import {
  getDiffInDays,
  // , timeout
} from '../../utils'
import POWERS from '../../powers'

const Header = styled.div`
  display: flex;
  padding: 15px;
  align-content: center;
  justify-content: space-between;

  background-color: #fff;
`

const FunnelPage = ({
  getData,
  collectionName,
  match,
  toolBar,
  CardContent,
}) => {
  const history = useHistory()

  const { funnelName } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [funnel, setFunnel] = useState()

  const [scheduleTaskModalCardId, setScheduleTaskModalCardId] = useState()
  const [taskToEdit, setTaskToEdit] = useState()

  const api = useTalentApi()

  const refreshState = React.useCallback(async () => {
    const { data } = await api.get('funnels', {
      params: { name: funnelName },
    })

    const funnelConfig = data[0]

    if (!funnelConfig) {
      return
    }

    const arr = await getData({
      statuses: funnelConfig.columns.map((c) => c.id),
    })

    fillFunnelWithCards(funnelConfig, arr)

    // hack.
    funnelConfig.timestamp = new Date().getTime()
    setFunnel(funnelConfig)
  }, [api, funnelName, getData])

  useEffect(() => {
    setIsLoading(true)
    refreshState().then(() => setIsLoading(false))
    // setInterval(refreshState, 5000);
  }, [match.params.funnelName, refreshState])

  useEffect(() => {
    if (!funnel) {
      return
    }

    document.title = `${funnel.title}`
  }, [funnel])

  const fillFunnelWithCards = (funnelToFill, cardsToAdd) => {
    funnelToFill.columns.forEach((column) => {
      column.cards = cardsToAdd.filter((x) => x.status === column.id)
    })
  }

  if (isLoading) {
    return <PageLoader />
  }

  if (!funnel) {
    return (
      <div style={{ padding: `25px 25px 0px 25px` }}>
        Opps... funnel not found.
      </div>
    )
  }

  return (
    <React.Fragment>
      <Header>
        <div>
          <b>View: </b>
          <DataSelect
            style={{ marginLeft: 5 }}
            url="funnels"
            clearable={false}
            render={(x) => x.title}
            value={funnel.name}
            orderBy={(x) => x.order}
            onChange={(e, target) =>
              history.push(`/${collectionName}/funnels/${target.value}`)
            }
            getValue={(x) => x.name}
            filter={{ collection: collectionName }}
          />

          <RequirePowers powers={[POWERS.master]}>
            <Button
              style={{ marginLeft: 5 }}
              basic
              icon="pencil"
              onClick={() =>
                history.push(`/${collectionName}/funnels/${funnel.name}/edit`)
              }
            />
          </RequirePowers>

          <Button
            style={{ marginLeft: 5 }}
            basic
            icon="refresh"
            onClick={() => {
              Object.keys(window.localStorage)
                .filter((key) => key.startsWith('cache-'))
                .forEach((key) => window.localStorage.removeItem(key))

              document.location.reload(true)
            }}
          />
        </div>
        <div style={{ display: 'flex' }}>{toolBar}</div>
      </Header>

      <div>
        <Board
          key={funnel.timestamp}
          funnel={funnel}
          onDragSuccess={(newFunnel) => setFunnel(newFunnel)}
        >
          {funnel &&
            funnel.columns.map((column, columnIndex) => (
              <Column
                title={column.title}
                id={column.id}
                key={column.id}
                rotting={column.rotting}
              >
                {(column.cards || []).map((card, index) => (
                  <FunnelCard
                    key={card._id}
                    collectionName={collectionName}
                    index={index}
                    card={card}
                    onScheduleTask={() => setScheduleTaskModalCardId(card._id)}
                    onTaskEdit={(task) => setTaskToEdit(task)}
                    isLastColumn={columnIndex > funnel.columns.length - 3}
                    isRotten={
                      column.rotting <=
                      getDiffInDays(card.lastStatusUpdate, Date.now())
                    }
                    CardContent={CardContent}
                  />
                ))}
              </Column>
            ))}
        </Board>
      </div>

      <AddTaskModal
        key={scheduleTaskModalCardId} // TODO: Revisar esto.
        show={scheduleTaskModalCardId || taskToEdit}
        onCancel={() => {
          setScheduleTaskModalCardId(null)
          setTaskToEdit(null)
        }}
        onCreated={async () => {
          setScheduleTaskModalCardId(null)
          await refreshState()
        }}
        onEdited={async () => {
          setTaskToEdit(null)
          await refreshState()
        }}
        taskToEdit={taskToEdit}
        relatedId={scheduleTaskModalCardId}
        relatedCollection={collectionName}
      />
    </React.Fragment>
  )
}

export default FunnelPage
