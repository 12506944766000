import React from 'react'
import RemoteValue from './remoteValue'

const ContactName = ({ id, noContact, email, showLoading }) => {
  if (!id) {
    return noContact || 'No Contact.'
  }

  if (email) {
    return (
      <RemoteValue
        id={id}
        collection="contacts"
        predicate={(x) => `${x.name} (${x.email})`}
        showLoading={showLoading}
      />
    )
  }

  return (
    <RemoteValue
      id={id}
      collection="contacts"
      predicate={(x) => x.name}
      showLoading={showLoading}
    />
  )
}

export default ContactName
