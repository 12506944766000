import { React, useEffect, useState } from 'react'
import { Rating } from 'semantic-ui-react'
import { useTalentApi } from '../../store/mainContext'

const RemoteStars = ({ collection, value, maxRating }) => {
  const [rating, setRating] = useState(0)

  const api = useTalentApi()

  useEffect(() => {
    if (!value) return
    api.get(`${collection}/${value}`).then(({ data }) => setRating(data.value))
  }, [value, api, collection])

  if (!value) {
    return <span>Not set yet.</span>
  }

  return <Rating maxRating={maxRating} rating={rating} icon="star" disabled />
}

export default RemoteStars
