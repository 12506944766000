import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Icon, Loader } from 'semantic-ui-react'
import { useTalentApi } from '../store/mainContext'

const Browser = styled.iframe`
  min-width: 500px;
  max-width: 500px;
  width: 500px;

  height: 100vh;
  border: none;
  border-left: dotted 1px var(--border-grey);

  position: sticky;
  top: 0px;
  /* box-shadow: 0 0px 5px 0 rgb(34 36 38 / 15%); */
`

const EmptyBrowser = styled.div`
  min-width: 500px;
  max-width: 500px;
  width: 500px;

  height: 100vh;
  border-left: dotted 1px var(--border-grey);

  position: sticky;
  top: 0;
  text-align: center;
  padding: 200px 100px;
  /* box-shadow: 0 0px 5px 0 rgb(34 36 38 / 15%); */
`

const LinkedinViewer = ({ url }) => {
  const api = useTalentApi()

  const [extensionInstalled, setExtensionInstalled] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    api
      .get(`/chrome-extension-check`)
      .then(({ data }) => setExtensionInstalled(data.installed))
      .catch(() => {})
      .finally(() => setIsLoading(false))
  }, [api])

  if (isLoading) {
    return (
      <EmptyBrowser>
        <Loader />
      </EmptyBrowser>
    )
  }

  if (!extensionInstalled) {
    return (
      <EmptyBrowser>
        <h1>
          <Icon size="big" name="download" />
        </h1>
        <h1>Chrome Extension Required</h1>

        <p>
          Download and Install the Chrome Extension to enable Linkedin Viewer.
        </p>

        <p>
          <a href="/rome-chrome-ext.zip" alt="Download chrome extension">
            Donwload Now
          </a>
        </p>
      </EmptyBrowser>
    )
  }

  if (!url || !url.indexOf('https://') === 0) {
    return (
      <EmptyBrowser>
        <h1>
          <Icon size="big" name="linkedin" />
        </h1>
        <h1>No Linkedin</h1>
      </EmptyBrowser>
    )
  }

  return <Browser samesite="none" secure="true" name="navframe" src={url} />
}

export default LinkedinViewer
