import React from 'react'
import styled from 'styled-components'

import SearchBox from './searchBox'
import RemoteValue from '../remoteValues/remoteValue'

const Name = styled.p`
  font-size: 1.3em;
  margin-bottom: 3px;
  font-weight: bold;
`

const Sub = styled.div`
  color: #61605f;

  p {
    line-height: 22px;
  }
`

const SearchPositions = () => (
  <SearchBox
    collection="positions"
    orderBy="lastUpdateOn"
    fields={['title', 'description', 'number']}
    render={({ number, title, companyId, hiringManagerId, status }) => (
      <React.Fragment>
        <Name>
          {number} - {title}
        </Name>
        <Sub>
          <p>
            <RemoteValue
              id={hiringManagerId}
              collection="contacts"
              predicate={(x) => x.name}
            />
            <br />
            <RemoteValue
              id={companyId}
              collection="companies"
              predicate={(x) => x.name}
            />
          </p>
          <p>
            <b>Status: </b> {status}
          </p>
        </Sub>
      </React.Fragment>
    )}
  />
)

export default SearchPositions
