// TODO: this component is deprecated, use native js for this.
const ShowIf = (opts) => {
  if (!opts.if) {
    return opts.false || null
  }

  const result = opts.true || opts.children

  return result || null
}

export default ShowIf
