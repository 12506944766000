import React from 'react'
import { useContractsApi } from '../../store/mainContext'
import SidePanel, { Divider } from '../sidepanel/sidePanel'
import TrFile from '../sidepanel/trFile'
import TrInput from '../sidepanel/trInput'
// import ShowIf from "../showIf";
import Period from '../period'
// import CompanyName from "../remoteValues/companyName";
import { useUser } from '../../pages/session/hooks'
import POWERS from '../../powers'

const Deal = ({ deal, customerSOW, onDealChanged, onCustomerSOWChanged }) => {
  const api = useContractsApi()
  const user = useUser()

  if (!deal) {
    return null
  }

  return (
    <React.Fragment>
      <SidePanel>
        <tbody>
          <tr>
            <th>Status</th>
            {deal.active ? (
              <td style={{ color: 'var(--olive)', fontWeight: 'bold' }}>
                Active {deal.effectiveDate > Date.now() && ' - Not started yet'}
              </td>
            ) : (
              <td style={{ color: 'var(--red)', fontWeight: 'bold' }}>
                Terminated {deal.endDate > Date.now() && '- Not ended yet'}
              </td>
            )}
          </tr>

          <tr>
            <th>SOW Effective Date</th>
            <td
              style={{
                color: deal.effectiveDate > Date.now() && 'var(--green)',
              }}
            >
              {new Date(deal.effectiveDate).toLocaleDateString('en-US')} (
              <Period date={deal.effectiveDate} />)
            </td>
          </tr>

          {deal.endDate && (
            <tr>
              <th>SOW End Date</th>
              <td
                style={{
                  color: deal.endDate > Date.now() && 'var(--green)',
                }}
              >
                {new Date(deal.endDate).toLocaleDateString('en-US')} (
                <Period date={deal.endDate} />)
              </td>
            </tr>
          )}

          <TrInput
            key={deal.position}
            injectedApi={api}
            name="position"
            label="Position"
            placeholder="Position..."
            value={deal.position}
            url={`deals/${deal._id}`}
            onSuccess={onDealChanged}
            disabled={!user.hasPower(POWERS.updateDeal)}
          />

          {/* this is moved to the contract.
        <tr>
          <th>Customer</th>
          <td>
            <a href={`/companies/${deal.customerId}`} target="_blank">
              <CompanyName id={deal.customerId} />
            </a>
          </td>
        </tr> */}

          {customerSOW && (
            <React.Fragment>
              <tr>
                <th>Customer Rate</th>
                <td>$ {customerSOW.monthlyRate}</td>
              </tr>

              <TrFile
                injectedApi={api}
                key={deal._id + customerSOW.sow}
                name="sow"
                label="Customer SOW"
                value={customerSOW.sow}
                url={`customerSOWs/${customerSOW._id}`}
                fileUrl={`cdn/customer-sows/sow-${customerSOW._id}`}
                onSuccess={onCustomerSOWChanged}
              />
            </React.Fragment>
          )}
        </tbody>
      </SidePanel>

      <Divider />

      <SidePanel>
        <tbody>
          <tr>
            <th>Contractor Fee</th>
            <td>$ {deal.monthlySalary}</td>
          </tr>

          <TrFile
            injectedApi={api}
            key={deal.contractorSOW}
            name="contractorSOW"
            label="Contractor SOW"
            value={deal.contractorSOW}
            url={`deals/${deal._id}`}
            fileUrl={`cdn/contractor-sows/contractor-sow-${deal._id}`}
            onSuccess={onDealChanged}
            disabled={!user.hasPower(POWERS.updateDeal)}
          />
        </tbody>
      </SidePanel>
    </React.Fragment>
  )
}

export default Deal
