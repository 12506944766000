import React, {
  //  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'
// import { useTalentApi } from '../../store/mainContext'
import DataFilter from './dataFilter'
// import SearchFilter from './searchFilter'
import SimpleFilter from './simpleFilter'
// import YearsFilter from './yearsFilter'

const FilterBar = styled.div`
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
  padding: 10px 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  border-bottom: solid 1px var(--border-grey);
  margin-bottom: -1px;
  z-index: 1;
  /* width: 450px; */
`

const FilterLabel = styled.div`
  margin: 5px;
  text-align: center;
`

const ContractsFilter = ({ onFilterChange, defaultFilter }) => {
  const [filter, setFilter] = useState(defaultFilter)

  const statusOptions = [
    {
      value: 0,
      text: 'Active Contracts',
      filter: { active: true },
    },
    {
      value: 1,
      text: 'Terminated Contracts',
      filter: { active: false },
    },
    { value: 2, text: 'All Contracts', filter: null },
  ]

  const handleChange = (additionalFilter) => {
    const newFilter = {
      ...filter,
      ...additionalFilter,
    }

    setFilter(newFilter)
    onFilterChange && onFilterChange(newFilter)
  }

  const handleChangeStatus = (_, name, value) => {
    const newFilter = { ...filter }

    delete newFilter.active

    // eslint-disable-next-line eqeqeq
    const selectedFilter = (statusOptions.find((x) => x.value == value) || {})
      .filter

    setFilter({ ...newFilter, ...selectedFilter })
    onFilterChange && onFilterChange({ ...newFilter, ...selectedFilter })
  }

  return (
    <FilterBar>
      <FilterLabel>Showing</FilterLabel>
      <SimpleFilter
        onFilterChange={handleChangeStatus}
        name="endDate"
        options={statusOptions}
      />
      <FilterLabel>working with</FilterLabel>
      <DataFilter
        onFilterChange={handleChange}
        name="customerId"
        label="All Customers"
        collection="companies"
        optionOrderBy={(x) => x.value}
        optionRender={(x) => x.name}
      />

      <FilterLabel>hired for</FilterLabel>
      <DataFilter
        onFilterChange={handleChange}
        name="hiringManagerId"
        label="All Hiring Managers"
        collection="contacts"
        optionOrderBy={(x) => x.value}
        optionRender={(x) => x.name}
      />

      <FilterLabel>under supervision of</FilterLabel>
      <DataFilter
        onFilterChange={handleChange}
        name="supervisorId"
        label="All Supervisors"
        collection="contacts"
        optionOrderBy={(x) => x.value}
        optionRender={(x) => x.name}
      />
    </FilterBar>
  )
}

export default ContractsFilter
