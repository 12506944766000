import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'

const TrCopyButton = ({ visible, value }) => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    setCopied(false)
  }, [visible])

  const handleClick = () => {
    navigator.clipboard.writeText(value)
    setCopied(true)
  }

  if (copied) {
    return (
      <Button
        disabled
        icon="check"
        size="tiny"
        onClick={handleClick}
        style={{
          position: 'absolute',
          right: 50,
          top: 8,
          padding: 7,
          margin: 0,
          marginTop: -2,
          backgroundColor: '#fff',
          border: 'solid 1px var(--border-grey)',
          display: visible ? 'block' : 'none',
          zIndex: 2,
        }}
      />
    )
  }

  return (
    <Button
      icon="copy"
      size="tiny"
      onClick={handleClick}
      style={{
        position: 'absolute',
        right: 50,
        top: 8,
        padding: 7,
        margin: 0,
        marginTop: -2,
        backgroundColor: '#fff',
        border: 'solid 1px var(--border-grey)',
        display: visible ? 'block' : 'none',
        zIndex: 2,
      }}
    />
  )
}

export default TrCopyButton
